import React from 'react';
import Icon from './Icon';
import { Link } from 'react-router-dom';
import { FaTwitter, FaDiscord } from 'react-icons/fa';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <a className="footer_link" href="https://twitter.com/zapper_fi" target="_blank" rel="noopener noreferrer">
            <Icon size="1.25em">
              <FaTwitter className="twitter"/>
            </Icon>
          </a>
          <a className="footer_link" href="https://discord.com/invite/5Gxxg8h" target="_blank" rel="noopener noreferrer">
            <Icon size="1.25em" isAccent="icon--accent">
              <FaDiscord className="discord"/>
            </Icon>
          </a>
          <Link className="footer_link" to="/walloffame">
            Wall of Fame
          </Link>
        </div>
      </div>
    )
  }
}

export default Footer;
