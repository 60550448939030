const initialState = {
  status: 'IS_MISSING',
  pools: [],
  ethPrice: 0
};

export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_UNISWAP':
    return action.payload
  case 'UNISWAP_SET_STATUS':
    return {
     ...state,
     status: action.payload,
    }
  case 'EXIT_DASHBOARD':
    return {
       ...state,
       status: action.payload,
     }
  default:
    return state
  }
}
