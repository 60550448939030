export const setWallets = (wallet) => dispatch => {
  const wallets = localStorage.getItem('wallets') ? JSON.parse(localStorage.getItem('wallets')) : [];
  wallets.push(wallet);

  localStorage.setItem('wallets', JSON.stringify(wallets));

  dispatch({
    type: 'SET_WALLETS',
    payload: wallets,
  })
}

export const setActiveWallet = (wallets, activeWallet) => dispatch => {
  const newWallets = wallets.map(x => {
    const isActive = x.address === activeWallet.address ? true : false;
    return {
      address: x.address,
      ens: x.ens,
      isActive
    }
  })

  localStorage.setItem('wallets', JSON.stringify(newWallets));

  dispatch({
    type: 'SET_ACTIVE_WALLET',
    payload: activeWallet,
  })

  dispatch({
    type: 'SET_WALLETS',
    payload: newWallets,
  })
}

export const removeWallet = (wallets, removedWallet) => dispatch => {
  const filteredWallets = wallets.filter(wallet => wallet !== removedWallet);
  const newWallets = filteredWallets.map((x, i) => {
    const isActive = i === 0 ? true : false;
    return {
      address: x.address,
      ens: x.ens,
      isActive
    }
  })

  if(newWallets.length === 0) {
    localStorage.removeItem('wallets');

    dispatch({
      type: 'SET_WALLETS',
      payload: [],
    })
  } else {
    localStorage.setItem('wallets', JSON.stringify(newWallets));

    dispatch({
      type: 'SET_WALLETS',
      payload: newWallets,
    })
  }
}

export const setBaseCurrency = (symbol) => dispatch => {
  localStorage.setItem('baseCurrency', symbol);

  dispatch({
    type: 'SET_BASE_CURRENCY',
    payload: symbol,
  })
}

export const fetchRate = (symbol) => async dispatch => {
  const rates = await fetch('https://api.exchangeratesapi.io/latest?base=USD').then(response => response.json()).then(data => data.rates);

  const rate = rates[symbol];

  dispatch({
    type: 'FETCH_RATE',
    payload: {
      symbol,
      rate
    },
  })
}

export const switchTheme = (theme) => dispatch => {
  dispatch({
    type: 'SWITCH_THEME',
    payload: theme,
  })
}

export const switchView = (view) => dispatch => {
  localStorage.setItem('view', view);

  dispatch({
    type: 'SWITCH_VIEW',
    payload: view,
  })
}

export const onExit = () => dispatch => {
  localStorage.removeItem('wallets');

  dispatch({
    type: 'EXIT_DASHBOARD',
    payload: 'IS_EXITED',
  })
}
