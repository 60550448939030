const initialState = {
  status: 'IS_MISSING',
  tokens: []
}

export default (state = initialState, action) => {
 switch (action.type) {
 case 'FETCH_STAKED':
   return {
     ...state,
     tokens: action.payload,
   }
 case 'STAKED_SET_STATUS':
   return {
     ...state,
     status: action.payload,
   }
 case 'EXIT_DASHBOARD':
   return {
      ...state,
      status: action.payload,
    }
 default:
   return state
 }
}
