import React from 'react';
import Modal from '../Modal';
import Icon from '../Icon';
import { IoMdExit, IoMdClipboard, IoMdAdd} from 'react-icons/io';
import Blockies from 'react-blockies';
import EditAddress from '../EditAddress';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import onClickOutside from "react-onclickoutside";
import { setActiveWallet } from 'actions/account';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  wallets: state.account.wallets
});

const mapDispatchToProps = dispatch => ({
  setActiveWallet: (wallets, wallet) => dispatch(setActiveWallet(wallets, wallet)),
});

class AccountDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      dropdownOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  toggleModal = () => {
    this.setState({
      dropdownOpen: !this.state.showModal,
      showModal: !this.state.showModal,
    })
  }

  onSelectWallet = (wallets, selectedWallet) => {
    this.props.setActiveWallet(wallets, selectedWallet);
    this.setState({
      dropdownOpen: false,
    })
  }

  handleClickOutside = evt => {
    this.setState({ dropdownOpen: false });
  };

  render() {
    const { dropdownOpen, showModal } = this.state;
    const { wallets } = this.props;

    return (
        <div className="dropdown dropdown_account">
          <div className="dropdown_account_header" onClick={this.toggle}>
            {this.props.children}
          </div>
          {
            (dropdownOpen && !showModal)  &&
            <div className="dropdown_list">
              {
                wallets.length ? (
                  <div className="dropdown_list_title">Wallet(s)</div>
                ) : null
              }
              {
                wallets.map(wallet => {
                  return (
                    <div className="dropdown_list_item wallet" key={wallet.address}>
                      <div className="dropdown_list_item_wallet" key={wallet.address} onClick={() => this.onSelectWallet(wallets, wallet)}>
                        <Blockies
                          seed={wallet.address.toLowerCase()}
                          size={10}
                          scale={3}
                          className="identicon_blockie"
                        />
                        {wallet.ens ? wallet.ens : `${wallet.address.substring(0, 6)}...${wallet.address.slice(-4)}`}
                      </div>
                      <div className="dropdown_list_item_end">
                        <CopyToClipboard text={wallet.address}>
                          <div className="dropdown_list_item_copy">
                            <Icon size="1em">
                              <IoMdClipboard />
                            </Icon>
                            <p ref={ref => this.fooRef = ref} data-tip='Copied!'></p>
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>
                  )
                })
              }
              <div className="dropdown_list_section">
                <div className="dropdown_list_link manage" onClick={this.toggleModal}>
                  <div className="icon_wrapper">
                    <Icon size="1.25em">
                      <IoMdAdd />
                    </Icon>
                  </div>
                  Add/Remove
                </div>
                <div className="dropdown_list_link no-hover">
                  <div className="dropdown_list_link_exit" onClick={this.props.onExit}>
                    Exit
                    <Icon size="1.25em">
                      <IoMdExit />
                    </Icon>
                  </div>
                </div>
              </div>
            </div>
          }
          <Modal
            isOpen={showModal}
            onRequestClose={this.toggleModal}
            title="Manage addresses"
          >
            <EditAddress onClose={this.toggleModal}/>
          </Modal>
        </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(AccountDropdown));
