const contributors = [
  'daryllautk',
  'maximfmai',
  'kowei1995',
  'sarujp',
  'akimc',
  'guillaumepalayer',
  'razgraf',
  'suhailgme',
  'nodar',
  'chainlinkgod',
  'defidude',
  'datapaul-lab',
  'nocturnalsheet',
  'xutpobah',
  'nclsaudet',
  'jiecut42',
  'jokazza84',
  'maproulx99',
  'justinjmoses',
  'kaiynne',
  'anaolsson',
  'tomoyan',
  '4-1-3-x',
  'mbaril010',
  'ecruz01',
  'kadenzipfel',
  'kmignacca',
  'pascaltallarida',
  'thereturnofjan',
  'faridrached',
  'coopahtroopanew',
  'buddybuddinson',
  'lucadonnoh',
  'k06a',
  'laserbach',
  'scottrepreneur',
  'alexandresobolevski',
  'evgenyboxer',
  'rockfridrich',
  'illlefr4u',
  'lalleclausen',
  'banteg',
  'leocap2017',
  'be1garath',
  'sterlu',
  'iancsnx',
  'synthaman',
  'hav-noms',
  'wkarshat',
  'jay-prime',
  'ethereumkev',
  'ivglavas',
  'milkyklim',
  'lcusack',
  'sweetpotatoz',
  'lucawint',
  'zeropool-hub',
  'tvist',
  'musatheredguard',
  'danielheyman',
  'portelos',
  'defidon',
  'paulrberg',
  'followthechain',
  'clementbalestrat',
  'zoe-archer',
  'jacko125',
  'frdra',
  'hjmomtazi',
  'kdm5126',
  'eeks',
  'charlieknoll',
  'markspereira',
  'heyellieday',
  'nickhollins',
  'pyggie',
  'defi-dad',
  'nklipa13',
  'adambawi',
  'provatidis',
  'ethwarrior',
  'seanatwork',
  'mds1',
  'tokenbrice',
  'drknudy'
]

export default contributors;
