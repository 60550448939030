import React from 'react';
import Switch from "react-switch";
import Moon from '../../global/images/moon.svg';
import Sun from '../../global/images/sun.svg';
import { connect } from 'react-redux';
import { switchTheme } from 'actions/account';

const mapDispatchToProps = dispatch => ({
  switchTheme: (theme) => dispatch(switchTheme(theme))
})

class ModeSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      darkMode: false,
    }

    this.onChange = this.onChange.bind(this);
  }

  onChange(checked) {
    this.setState({
      darkMode: checked
    }, () => {
      if(this.state.darkMode === true) {
        localStorage.setItem('theme', 'dark');
        this.props.switchTheme('dark');
      } else {
        localStorage.setItem('theme', 'light');
        this.props.switchTheme('light');
      }
    });
  }

  componentDidMount() {
    const theme = localStorage.getItem('theme');

    this.setState({
      darkMode: theme === 'dark' ? true : false,
    })
  }

  render() {
    const { darkMode } = this.state;

    return (
      <div className="themeSwitch">
        <Switch
          onChange={this.onChange}
          checked={darkMode}
          offColor="#E9F1FF"
          onColor="#6900FF"
          height={40}
          width={70}
          handleDiameter={24}
          checkedIcon={
            <div className="themeSwitch_icon">
              <img src={Moon} alt="moon"/>
            </div>
          }
          uncheckedIcon={
            <div className="themeSwitch_icon">
              <img src={Sun} alt="sun"/>
            </div>
          }
        />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(ModeSwitch);
