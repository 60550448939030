import React from 'react';
import Container from '../../shared/Container';
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import contributors from './contributors';

class WallOfFame extends React.Component {
  render() {
    return (
      <Container>
        <Header page="home"/>
        <div className="container">
          <h1 style={{marginBottom: 10}}>Wall of Fame <span role="img">🏆</span></h1>
          <h3 style={{marginTop: 0}}>
            Thank you to all our Gitcoin contributors!
          </h3>
          <p>Want to be featured on the wall?
            <a
              className="text-link"
              href="https://gitcoin.co/grants/429/defisnap-2"
              rel="noopener noreferrer"
              target="_blank">
              {` `}Contribute now.
            </a>
          </p>
          <div className="wall grid">
            {
              contributors.map(contributor => (
                <a key={contributor} className="wall_item" href={`https://gitcoin.co/profile/${contributor}`} rel="noopener noreferrer" target="_blank">
                  <img src={`https://gitcoin.co/dynamic/avatar/${contributor}`} alt=""/>
                  {contributor}
                </a>
              ))
            }
          </div>
        </div>
        <Footer />
      </Container>
    )
  }
}

export default WallOfFame;
