import React from 'react';
import Container from '../shared/Container';
import Header from '../shared/Header';
import Footer from '../shared/Footer';

class Home extends React.Component {
  render() {
    return (
      <Container>
        <Header page="home"/>
        <div className="container">
          <div className="home_hero home_section">
            <div className="home_hero_block">
              <h1>Introducing <span className="accent_color">Zapper.fi</span></h1>
              <h3>Zapper = DeFiSnap + DeFiZap coming together to form the ultimate hub for managing your DeFi assets & liabilities.</h3>
              <form className="home_hero_cta">
                <a className="button button-main" href="https://zapper.fi">Go to Zapper.fi</a>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </Container>
    )
  }
}

export default Home;
