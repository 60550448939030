const initialState = {
  status: 'IS_MISSING',
  tokens: []
}

export default (state = initialState, action) => {
  switch (action.type) {
  case 'FETCH_BALANCER':
    return {
      ...state,
      tokens: action.payload,
    }
  case 'BALANCER_SET_STATUS':
    return {
      ...state,
      status: action.payload,
    }
  case 'EXIT_DASHBOARD':
    return {
       ...state,
       status: action.payload,
     }
  default:
    return state
  }
}
