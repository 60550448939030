const initialState = {
  status: 'IS_MISSING',
  vaults: [],
  dsr: 0,
  daiPrice: 0,
  dsrRate: 0
}


export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_MAKER':
    return action.payload
  case 'MAKER_SET_STATUS':
    return {
     ...state,
     status: action.payload,
    }
  case 'EXIT_DASHBOARD':
    return {
       ...state,
       status: action.payload,
     }
  default:
    return state
  }
}
