import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import rootReducer from './reducers/rootReducer';

const middlewares = [
  thunk,
  // process.env.NODE_ENV === 'development' && logger,
];

function configureStore(initialState={}) {
 return createStore(
   rootReducer,
   initialState,
   applyMiddleware(...middlewares)
 );
}

const store = configureStore();

export default store;
