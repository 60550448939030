import React from 'react';
import ReactModal from 'react-modal';
import Icon from '../shared/Icon';
import { IoMdOpen, IoMdClose } from 'react-icons/io';

class Modal extends React.Component {
  render() {
    const theme = localStorage.getItem('theme');

    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        className={theme === 'dark' ? 'modal theme--dark' : 'modal theme--light'}
        overlayClassName="modal_overlay"
        ariaHideApp={false}
      >
        <div className="modal_content">
          <h4 className="modal_title">{this.props.title}</h4>
          <p className="modal_instructions">{this.props.instructions}</p>
          <div className="modal_body">
            {this.props.children}
          </div>
          <div className="modal_actions">
            {
              this.props.onLink ? (
                <a target="_blank" rel="noopener noreferrer" className="modal_actions_item" href={this.props.onLink}>
                  <Icon size="1.1em">
                    <IoMdOpen />
                  </Icon>
                </a>
              ) : null
            }
            <div className="modal_actions_item" onClick={this.props.onRequestClose}>
              <Icon size="1.2em">
                <IoMdClose />
              </Icon>
            </div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default Modal;
