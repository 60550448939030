const initialState = {
  status: 'IS_MISSING',
  supplied: [],
  borrowed: [],
  curve: []
}

export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_COMPOUND':
    return action.payload
  case 'COMPOUND_SET_STATUS':
    return {
      ...state,
      status: action.payload,
    }
  case 'EXIT_DASHBOARD':
    return {
      ...state,
      status: action.payload,
    }
  default:
    return state
  }
}
