import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store';
import App from './App';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <App className={'theme ' + (store.switchMode ? 'theme--dark' : 'theme--default')}/>
  </Provider>,
  document.getElementById('root')
);

// serviceWorker.register();
