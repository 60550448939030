import React from 'react';
import Alert from '../shared/Alert';
import Web3 from 'web3';
import Blockies from 'react-blockies';
import Icon from './Icon';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { setWallets, setActiveWallet, removeWallet } from 'actions/account';
import { ethers } from 'ethers';
import { connect } from 'react-redux';

const provider = new ethers.getDefaultProvider();

const mapStateToProps = state => ({
  wallets: state.account.wallets,
});

const mapDispatchToProps = dispatch => ({
  setWallets: (wallet) => dispatch(setWallets(wallet)),
  setActiveWallet: (wallets, wallet) => dispatch(setActiveWallet(wallets, wallet)),
  removeWallet: (wallets, wallet) => dispatch(removeWallet(wallets, wallet)),
});

class EditAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      items: this.props.wallets,
      validAlert: false,
      addressAlert: false,
      alreadyAddress: true,
    }

    this.addItem = this.addItem.bind(this);
  }

  addItem(e) {
    const { items } = this.state;
    e.preventDefault();

    if (this._inputElement.value !== "") {
      if(items.length <= 4) {
        this.resolveENS(this._inputElement.value);
      } else {
        this.setState({
           addressAlert: true,
        });
      }
    }

    this._inputElement.value = "";
  }

  resolveENS = async (input) => {
    const { items } = this.state;
    const filteredInput = input.replace(/\s/g, '');
    const validAddress = Web3.utils.isAddress(filteredInput);

    if(filteredInput.includes('.eth') || filteredInput.includes('argent')) {
      await provider.resolveName(filteredInput)
      .then(response => {
        if(response !== null) {
          if(items.includes(response)) {
            this.setState({
               alreadyAlert: true,
            });
          } else {
            const wallet = {
              address: response,
              ens: filteredInput,
            }
            this.props.setWallets(wallet);
          }
        }
      })
      .catch(err => {
        this.setState({
           validAlert: true,
        });
      })
    } else if(validAddress) {
      if(items.includes(filteredInput)) {
        this.setState({
           alreadyAlert: true,
        });
      } else {
        const ens = await provider.lookupAddress(filteredInput).then(response => response).catch(err => null);

        const wallet = {
          address: filteredInput,
          ens,
        }
        this.props.setWallets(wallet);
      }
    } else {
      this.setState({
         validAlert: true,
      });
    }
  }

  onSelectWallet = (wallets, selectedWallet) => {
    this.props.setActiveWallet(wallets, selectedWallet);

    this.props.onClose();
  }

  onRemoveWallet = (wallets, removedWallet) => {
    const filteredWallets = wallets.filter(wallet => wallet !== removedWallet);
    this.props.removeWallet(wallets, removedWallet);

    this.setState({
      items: filteredWallets
    })
  }

  render() {
    const { wallets } = this.props;
    const { validAlert, addressAlert, alreadyAlert } = this.state;

    return(
      <div>
        <form className="editAddress" onSubmit={this.addItem}>
          <div className="flex">
            <input ref={(a) => this._inputElement = a} placeholder="Add ENS domain or valid ETH address" className="input editAddress_input"/>
            <input type="submit" value="+" className="button button-add" />
          </div>
          {
            wallets.map(i => {
              return (
                <div className="editAddress_item" key={i.address} >
                  <div className="editAddress_item_left" onClick={() => this.onSelectWallet(wallets, i)}>
                    <Blockies
                      seed={i.address.toLowerCase()}
                      size={10}
                      scale={3}
                      className="identicon_blockie"
                    />
                    {i.ens ? i.ens : `${i.address.substring(0, 6)}...${i.address.slice(-4)}`}

                  </div>
                  <div className="editAddress_item_close" onClick={() => this.onRemoveWallet(wallets, i)}>
                    <Icon size="1.75em">
                      <IoIosCloseCircleOutline />
                    </Icon>
                  </div>
                </div>
              )
            })
          }
        </form>
        {
          validAlert &&
          <Alert
            validAlert={validAlert}
            message="Not a valid Ethereum address"
          />
        }
        {
          addressAlert &&
          <Alert
            validAlert={addressAlert}
            message="Max. 5 addresses"
          />
        }
        {
          alreadyAlert &&
          <Alert
            validAlert={alreadyAlert}
            message="You've already added this address."
          />
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress);
