import React from 'react';
import Icon from './Icon';
import { IoMdOpen } from 'react-icons/io';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  theme: state.account.theme,
})

class Container extends React.Component {
  render() {
    const theme = localStorage.getItem('theme');

    return (
      <div className={theme === 'dark' ? 'theme--dark' : 'theme--light'}>
        <div className="background" />
        <div className="app">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(Container);
