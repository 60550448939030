import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  theme: state.account.theme,
})

class Loading extends React.Component {
  render() {
    const theme = localStorage.getItem('theme');

    return (
      <div className={theme === 'dark' ? 'theme--dark' : 'theme--light'}>
        <div className="loading">
          <div className="loading_indicator" />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, null)(Loading);
