const initialState = {
  status: 'IS_MISSING',
  account: {
    collateral: 0,
    debtBalance: 0,
    usdToSnxPrice: 0,
    unlockedSnx: 0,
    synths: [],
    lpRewards: {
      ethBalance: 0,
      sethBalance: 0,
      share: 0,
      estimatedRewards: 0,
      availableRewards: 0
    }
  },
  info: {
    issuanceRatio: 0,
    usdToSnxPrice: 0,
    unlockedSnx: 0,
    collateral: 0,
    collateralRatio: 0,
    sUSDBalance: 0,
    debtBalance: 0,
    currentFeesAvailable: 0,
    currentRewardsAvailable: 0,
    escrow: 0,
    estimatedRewards: 0,
    estimatedFees: 0
  },
  loans: [],
  debtHistory: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SYNTHETIX_INFO':
      return {
        ...state,
        info: action.payload,
      }
    case 'FETCH_SYNTHETIX':
      return {
        ...state,
        account: action.payload,
      }
    case 'FETCH_LOANS':
      return {
        ...state,
        loans: action.payload,
      }
    case 'GET_DEBT_HISTORY':
      return {
        ...state,
        debtHistory: action.payload,
      }
    case 'SYNTHETIX_SET_STATUS':
      return {
        ...state,
        status: action.payload,
      }
    case 'EXIT_DASHBOARD':
      return {
         ...state,
         status: action.payload,
       }
    default:
      return state
    }
}
