import React from 'react';
import { IconContext } from "react-icons";

class Icon extends React.Component {
  render() {
    return (
      <IconContext.Provider value={{ className: `icon ${this.props.isAccent}`, size: this.props.size }}>
        <div className="flex">
          {this.props.children}
        </div>
      </IconContext.Provider>
    )
  }
}

export default Icon;
