const initialState = {
  status: 'IS_MISSING',
  iTokens: [],
  pTokens: []
}

export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_FULCRUM':
    return action.payload
  case 'FULCRUM_SET_STATUS':
    return {
      ...state,
      status: action.payload,
    }
  case 'EXIT_DASHBOARD':
    return {
       ...state,
       status: action.payload,
     }
  default:
    return state
  }
}
