import React from 'react';
import Logo from '../../global/images/logo.svg';
import { Link } from 'react-router-dom';
import Blockies from 'react-blockies';
import Icon from './Icon';
import { IoMdArrowDropdown, IoMdArrowBack } from 'react-icons/io';
import ThemeSwitch from './ThemeSwitch';
import AccountDropdown from '../shared/dropdowns/AccountDropdown';
import CurrencyDropdown from '../shared/dropdowns/CurrencyDropdown';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { setBaseCurrency } from 'actions/account';

const mapStateToProps = state => ({
  wallets: state.account.wallets,
  activeWallet: state.account.wallets.find(x => x.isActive),
  baseCurrency: state.account.baseCurrency
});

const mapDispatchToProps = dispatch => ({
  setBaseCurrency: (symbol) => dispatch(setBaseCurrency(symbol)),
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      selected: this.props.baseCurrency,
    }
  }

  onSelectCurrency = (currency) => {
    this.props.setBaseCurrency(currency);

    this.setState({selected: currency});
  }

  goBack(){
    this.props.history.goBack();
  }

  render() {
    const { activeWallet, baseCurrency, title, icon } = this.props;

    return (
      <div className={`container ${this.props.page}`}>
        <div className="header">
          {
            title ? (
              <div className="header_brand">
                <div className="header_brand_back" onClick={this.goBack}>
                  <Icon size="1.5em">
                    <IoMdArrowBack />
                  </Icon>
                </div>
                {
                  icon ? (
                    <img className="header_brand_icon" src={`/images/${icon}`} alt={`${title} Icon`}/>
                  ) : null
                }
                <span className="header_brand_name header_brand_name--sub">
                  {title}
                </span>
              </div>
            ) : (
              <Link to="/" className="header_brand">
                <img className="header_brand_logo" src={Logo} alt="DefiSnap Logo" />
                <span className="header_brand_name">
                  DefiSnap
                </span>
              </Link>
            )
          }
          {
            this.props.onExit &&
            <AccountDropdown
              onExit={this.props.onExit}
            >
              <Blockies
                seed={activeWallet.address.toLowerCase()}
                size={10}
                scale={3}
                className="identicon_blockie"
              />
              <span className="identicon_address">
                {activeWallet.ens ? activeWallet.ens : `${activeWallet.address.substring(0, 6)}...${activeWallet.address.slice(-4)}`}
              </span>
              <Icon size="1.1em">
                <IoMdArrowDropdown />
              </Icon>
            </AccountDropdown>
          }
          <div className="header_right hidden-mobile">
            {
              this.props.onExit &&
              <CurrencyDropdown onSelect={this.onSelectCurrency}>
                <img className="currency_icon" src={`/images/${baseCurrency}.svg`} alt={`${baseCurrency} Icon`} />
                {baseCurrency}
                <Icon size="1.1em">
                  <IoMdArrowDropdown />
                </Icon>
              </CurrencyDropdown>
            }
            <ThemeSwitch />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
