import React from 'react';
import onClickOutside from "react-onclickoutside";

class CurrencyDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleClickOutside = evt => {
    this.setState({ dropdownOpen: false });
  };

  render() {
    const { dropdownOpen } = this.state;

    return (
        <div className="dropdown dropdown_currency" onClick={this.toggle}>
          <div className="dropdown_currency_header">
            {this.props.children}
          </div>
          {
            dropdownOpen &&
            <div className="dropdown_list">
              <div className="row dropdown_list_row">
                <div className="column">
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('USD')}>
                    <img className="currency_icon" src='/images/USD.svg' alt='USD Icon' />
                    USD
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('EUR')}>
                    <img className="currency_icon" src='/images/EUR.svg' alt='EUR Icon' />
                    EUR
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('GBP')}>
                    <img className="currency_icon" src='/images/GBP.svg' alt='GBP Icon' />
                    GBP
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('CAD')}>
                    <img className="currency_icon" src='/images/CAD.svg' alt='CAD Icon' />
                    CAD
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('AUD')}>
                    <img className="currency_icon" src='/images/AUD.svg' alt='AUD Icon' />
                    AUD
                  </div>
                </div>
                <div className="column">
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('CNY')}>
                    <img className="currency_icon" src='/images/CNY.svg' alt='CNY Icon' />
                    CNY
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('SGD')}>
                    <img className="currency_icon" src='/images/SGD.svg' alt='SGD Icon' />
                    SGD
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('KRW')}>
                    <img className="currency_icon" src='/images/KRW.svg' alt='KRW Icon' />
                    KRW
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('JPY')}>
                    <img className="currency_icon" src='/images/JPY.svg' alt='JPY Icon' />
                    JPY
                  </div>
                  <div className="dropdown_list_item" onClick={() => this.props.onSelect('RUB')}>
                    <img className="currency_icon" src='/images/RUB.svg' alt='RUB Icon' />
                    RUB
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
    );
  }
}

export default onClickOutside(CurrencyDropdown);
