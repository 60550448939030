const initialState = {
  status: 'IS_MISSING',
  ethereum: {},
  erc20: []
}

export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_TOKENS':
    return action.payload
  case 'TOKENS_SET_STATUS':
    return {
     ...state,
     status: action.payload,
    }
  case 'EXIT_DASHBOARD':
    return {
       ...state,
       status: action.payload,
     }
  default:
    return state
  }
}
