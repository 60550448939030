import { combineReducers } from 'redux';
import account from './account';
import tokens from './tokens';
import synthetix from './synthetix';
import uniswap from './uniswap';
import maker from './maker';
import aave from './aave';
import compound from './compound';
import dydx from './dydx';
import fulcrum from './fulcrum';
import poolTogether from './poolTogether';
import tokenSets from './tokenSets';
import staked from './staked';
import iearn from './iearn';
import idle from './idle';
import defizap from './defizap';
import realT from './realT';
import zrx from './zrx';
import opyn from './opyn';
import defisaver from './defisaver';
import lendf from './lendf';
import hegic from './hegic';
import balancer from './balancer';
import shell from './shell';
import curve from './curve';
import transactions from './transactions';

export default combineReducers({
  account,
  iearn,
  tokens,
  synthetix,
  uniswap,
  maker,
  aave,
  compound,
  fulcrum,
  poolTogether,
  dydx,
  tokenSets,
  defisaver,
  staked,
  defizap,
  idle,
  transactions,
  realT,
  zrx,
  opyn,
  lendf,
  hegic,
  balancer,
  curve,
  shell
});
