const initialState = {
  activeWallet: null,
  wallets: localStorage.getItem('wallets') ? JSON.parse(localStorage.getItem('wallets')) : [],
  theme: localStorage.getItem('theme'),
  view: localStorage.getItem('view') ? localStorage.getItem('view') : 'grid',
  baseCurrency: localStorage.getItem('baseCurrency') ? localStorage.getItem('baseCurrency') : 'USD',
  rate: {
    symbol: localStorage.getItem('baseCurrency') ? localStorage.getItem('baseCurrency') : 'USD',
    rate: 1
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_WALLET':
      return {
        ...state,
        activeWallet: action.payload,
      }
    case 'SET_WALLETS':
      return {
        ...state,
        wallets: action.payload,
      }
    case 'SET_BASE_CURRENCY':
      return {
        ...state,
        baseCurrency: action.payload,
      }
    case 'FETCH_RATE':
      return {
        ...state,
        rate: action.payload,
      }
    case 'SWITCH_THEME':
      return {
        ...state,
        theme: action.payload,
      }
    case 'SWITCH_VIEW':
      return {
        ...state,
        view: action.payload,
      }
    default:
      return state
  }
}
