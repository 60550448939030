import React from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import './global/styles/app.scss';
import Loading from './components/shared/Loading';

// Screens
import Home from './components/screens/Home';
import WallOfFame from './components/screens/WallOfFame/WallOfFame';

class App extends React.Component {
  render() {
    return (
      <Router basename="/">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/walloffame">
            <WallOfFame />
          </Route>
          <Route path="">
            <Home />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
