import React from 'react';

class Alert extends React.Component {
  render() {
    const { message } = this.props;

    return (
      <div className="alert">
        <span />
        {message}
      </div>
    )
  }
}

export default Alert;
