const initialState = {
  status: 'IS_MISSING',
  supplied: [],
  positions: [],
  borrowed: [],
}


export default (state = initialState, action) => {
 switch (action.type) {
  case 'FETCH_DYDX':
   return action.payload
  case 'DYDX_SET_STATUS':
   return {
     ...state,
     status: action.payload,
   }
  case 'EXIT_DASHBOARD':
    return {
       ...state,
       status: action.payload,
     }
  default:
    return state
  }
}
