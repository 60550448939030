const initialState = {
  status: 'IS_MISSING',
  tokens: []
}

export default (state = initialState, action) => {
  switch (action.type) {
  case 'FETCH_LENDF':
    return {
      ...state,
      tokens: action.payload,
    }
  case 'LENDF_SET_STATUS':
    return {
      ...state,
      status: action.payload,
    }
  case 'EXIT_DASHBOARD':
    return {
      ...state,
      status: action.payload,
    }
  default:
    return state
  }
}
